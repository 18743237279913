<main class="main legal-page">
    <div class="main-wrapper">
        <header>
            <a [routerLink]="['/']" class="logo">
                <img src="../assets/images/account_logo.svg" >
            </a>
            <div class="links">
                <a [routerLink]="['/blog']"class="header-link blog-link">
                    <span><img src="../assets/images/blog_dark.svg"></span>
                    <span>Блог</span>
                </a>
                <a [routerLink]="['/login']"class="header-link">
                    <span><img src="../assets/images/account_dark.svg" ></span>
                </a>
            </div>
        </header>
        <div class="content">
            <div class="legal-heading">
                <h2>Политика конфиденциальности</h2>
                <strong>
                    в отношении обработки персональных данных
                </strong>
                <p>(далее по тексту – «Политика конфиденциальности»)</p>
                <em>Версия от «18» июля 2024 г., Российская Федерация, г. Москва</em>
            </div>
            <h3>1. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
            <ol>
                <li>
                    <strong>1.1</strong> Политика конфиденциальности определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, которые ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ВЕДА», в лице Генерального директора Цокуновой Яны Игоревны, действующего на основании Устава, может получить о Пользователе с помощью веб-сайта расположенного по постоянному URL-адресу: <a href="https://eastragene.com" target="_blank">eastragene.com</a>, распространяется на обработку персональных данных независимо от местонахождения, собранных с помощью активных или пассивных средств, с использованием сети «Интернет».
                </li>
                <li>
                    <strong>1.2 </strong>Политика конфиденциальности разработана в соответствии с Конституцией Российской Федерации, Федеральным законом «О персональных данных» №152-ФЗ от 27.07.2006 и другими нормативно-правовыми и подзаконными актами Российской Федерации, принятыми на их исполнение, с целью защиты прав и свобод человека и гражданина при обработке его персональных данных, защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
                </li>
                <li>
                    <strong>1.3</strong> Получить доступ к веб-сайту и использовать его по назначению Пользователь имеет право только после добровольного предоставления конкретного, предметного, информированного, сознательного и однозначного согласия на обработку его персональных данных на условиях, определенных в Политике конфиденциальности, предоставленного по своей воле и в собственных интересах установленным на веб-сайте способом.
                </li>
                <li>
                    <strong>1.4</strong> Если Пользователь не согласен с обработкой своих персональных данных на условиях, изложенных в Политике конфиденциальности, он обязан воздержаться от использования веб-сайта, так как это предполагает обработку его персональных данных в соответствии с Политикой конфиденциальности.
                </li>
                <li>
                    <strong>1.5</strong> Предоставляя свое согласие на обработку персональных данных Оператор исходит из того, что Пользователь предварительно ознакомился с текстом Политики конфиденциальности, персональные данные достоверны и принадлежат лично ему, предоставлены в актуальном на день передачи состоянии, Пользователь обладает полной дееспособностью и соглашается с обработкой его персональных данных на условиях, изложенных в Политике конфиденциальности. 
                </li>
                <li>
                    <strong>1.6</strong> Пользователь добровольно, по своей воле и в собственных интересах, дает свое конкретное, предметное, информированное, сознательное и однозначное согласие на обработку его персональных данных на условиях, предусмотренных Политикой конфиденциальности, в соответствии с законодательством Российской Федерации, понимает и соглашается со всеми правовыми последствиями таких действий, предварительно ознакомившись со своими правами и обязанностями в качестве субъекта персональных данных.
                </li>
                <li>
                    <strong>1.7</strong> В случае если Пользователь является лицом с ограниченной дееспособностью, Оператор исходит из того, что согласие на обработку его персональных данных предоставлено законным представителем, который обладает достаточным объемом прав и полномочий действовать от его имени и в его интересах, в полной мере ознакомился и принял условия обработки персональных данных, определенные в Политике конфиденциальности.
                </li>
                <li>
                    <strong>1.8</strong> Правовые основания обработки персональных данных: 
                    <ul>
                        <li>
                            - Конституция Российской Федерации; 
                        </li>
                        <li>
                            - Гражданский кодекс Российской Федерации; 
                        </li>
                        <li>
                            - Федеральный закон Российской Федерации «О персональных данных» №152-ФЗ от 27.07.2006; 
                        </li>
                        <li>
                            - Федеральный закон Российской Федерации «Об информации, информационных технологиях и о защите информации» №149-ФЗ от 27.07.2006; 
                        </li>
                        <li>
                            - Федеральный закон Российской Федерации «О рекламе» №38-ФЗ от 13.03.2006; 
                        </li>
                        <li>
                            - Постановление Правительства Российской Федерации «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных» №1119 от 01.11.2012; 
                        </li>
                        <li>
                            - Постановление Правительства Российской Федерации «Об утверждении Положения об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации» №687 от 15.09.2008; 
                        </li>
                        <li>
                            - Приказ ФСТЭК Российской Федерации «Об утверждении Состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных» №21 от 18.02.2013; 
                        </li>
                        <li>
                            - Регламент (ЕС) 2016/679 Европейского Парламента и Совета Европейского Союза «О защите физических лиц в отношении обработки персональных данных и о свободном перемещении таких данных, а также об отмене Директивы 95/46/ЕС»; 
                        </li>
                        <li>
                            - Иные принятые нормативно-правовые и подзаконные акты законодательства Российской Федерации в сфере сбора, обработки и защиты персональных данных; 
                        </li>
                        <li>
                            - Гражданско-правовой договор (размещен на веб-сайте);
                        </li>
                        <li>
                            - Согласие на обработку персональных данных (размещено на веб-сайте);
                        </li>
                        <li>
                            - Согласие на отправку уведомлений рекламно-информационного характера (размещено на веб-сайте).
                        </li>
                    </ul>
                </li>
            </ol>
            <h3>2. ИСПОЛЬЗУЕМЫЕ ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h3>
            <ol>
                <li>
                    <strong>2.1</strong> Для целей применения и толкования Политики конфиденциальности Оператором используются установленные ниже основные термины (если в тексте прямо не указано иное). Указанные термины могут быть использованы в другом падеже, в единственном или множественном числе, с маленькой или большой буквы, либо в виде сокращений:
                    <ul>
                        <li>
                            <strong>2.1.1</strong> «Автоматизированная обработка персональных данных» — обработка персональных данных с помощью средств вычислительной техники.
                        </li>
                        <li>
                            <strong>2.1.2</strong> «Блокирование персональных данных» — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).
                        </li>
                        <li>
                            <strong>2.1.3</strong> «Веб-сайт» — совокупность программ для ЭВМ, баз данных, графических, аудиовизуальных и информационных материалов, содержащихся в информационной системе, доступ к которой обеспечивается посредством сети «Интернет», расположенный по постоянному URL-адресу: <a href="https://eastragene.com" target="_blank">eastragene.com</a>, включающий в себя все уровни указанного домена, как действующие, так и вводимые в эксплуатацию в течении всего срока его действия, а также исходящие от него страницы («лендинги»).
                        </li>
                        <li>
                            <strong>2.1.4</strong> «Информационная система персональных данных» — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств.
                        </li>
                        <li>
                            <strong>2.1.5</strong> «Обезличивание персональных данных» — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.
                        </li>
                        <li>
                            <strong>2.1.6</strong> «Обработка персональных данных» — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение Персональных данных.
                        </li>
                        <li>
                            <strong>2.1.7</strong> «Оператор» — ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ВЕДА», в лице Генерального директора Цокуновой Яны Игоревны, действующего на основании Устава (ОГРН: 1237700856887; ИНН 7743433550), являющийся правообладателем веб-сайта.
                        </li>
                        <li>
                            <strong>2.1.8</strong> «Персональные данные» — любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю.
                        </li>
                        <li>
                            <strong>2.1.9</strong> «Персональные данные, разрешенные субъектом персональных данных для распространения» - персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Федеральным законом «О персональных данных» №152-ФЗ от 27.07.2006.
                        </li>
                        <li>
                            <strong>2.1.10</strong> «Пользователь» («Субъект персональных данных») — любое физическое лицо, обладающее полной дееспособностью, которое добровольно, по своей воле и в собственных интересах, предоставило Оператору свое конкретное, предметное, информированное, сознательное и однозначное согласие на обработку Оператором его персональных данных либо персональных данных несовершеннолетнего лица, в интересах которого оно действует в качестве законного представителя, на условиях Политики конфиденциальности.
                        </li>
                        <li>
                            <strong>2.1.11</strong> «Предоставление персональных данных» — действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.
                        </li>
                        <li>
                            <strong>2.1.12</strong> «Распространение персональных данных» — любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.
                        </li>
                        <li>
                            <strong>2.1.13</strong> «Трансграничная передача персональных данных» — передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.
                        </li>
                        <li>
                            <strong>2.1.14</strong> «Уничтожение персональных данных» — любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.
                        </li>
                        <li>
                            <strong>2.1.15</strong> «Файлы "cookie"» — небольшой фрагмент данных (в т. ч. текстовые файлы), отправляемые сервером веб-сайта и хранимые на компьютере Пользователя, которые веб-клиент или веб-браузер каждый раз пересылает на сервер веб-сайта в НТТР-запросе при попытке открыть соответствующую страницу веб-сайта. Файлы "cookie" обеспечивают сбор информации о действиях Пользователя на веб-сайте и отслеживают статистику посещения веб-сайта.
                        </li>
                    </ul>
                </li>
                <li>
                   <strong>2.2</strong> Политика конфиденциальности может содержать иные термины, не предусмотренные настоящим разделом, в связи с чем, толкование такого термина осуществляется в соответствии с законодательством Российской Федерации, а в случае отсутствия однозначности определения, в соответствии с обычаями делового оборота.
                    
                </li>
            </ol>
            <h3>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</h3>
            <ol>
                <li>
                    <strong>3.1</strong> Политика конфиденциальности устанавливает обязательства Оператора по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет в связи с использованием веб-сайта, а также основания, перечень, цели, сроки, способы, принципы, порядок и условия обработки и хранения персональных данных Пользователя, права и обязанности сторон, порядок получения согласия и отзыва согласия на обработку персональных данных.
                </li>
                <li>
                    <strong>3.2</strong> Оператор при обработке персональных данных Пользователя обеспечивает полную конфиденциальность персональных данных и соблюдение установленных законодательством Российской Федерации требований.
                </li>
            </ol>
            <h3>4. ПЕРЕЧЕНЬ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ И КАТЕГОРИИ СУБЪЕКТОВ</h3>
            <ol>
                <li>
                    <strong>4.1</strong> Персональные данные, разрешенные к обработке в рамках Политики конфиденциальности, предоставляются Пользователем путём заполнения специальных форм на веб-сайте, и включают в себя следующую информацию: 
                    <ul>
                        <li>
                            - фамилия, имя, отчество;
                        </li>
                        <li>
                            - исло, месяц и год рождения;
                        </li>
                        <li>
                            - дрес электронной почты;
                        </li>
                        <li>
                            - тип питания;
                        </li>
                        <li>
                            - дата и длина женского цикла;
                        </li>
                        <li>
                            - тап фаз женского цикла;
                        </li>
                        <li>
                            - файлы "cookie", автоматически передаваемые и обезличенные данные сервисов интернет-статистики («Яндекс Метрика»), уникальные данные об оборудовании (IP адрес, информация о виде операционной системы устройства и браузере, технические характеристики оборудования и программного обеспечения), географическое местоположение, дата, время использования веб-сайта и реферер (адрес предыдущей страницы).
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>4.2</strong> В целях информирования Пользователя и получения от него согласия Оператор размещает на веб-сайте уведомление следующего характера: «Мы используем "cookie" для быстрой и удобной работы сайта. Продолжая навигацию по сайту, вы разрешаете их использование и даете свое согласие на обработку файлов "cookiе".
                </li>
                <li>
                    <strong>4.3</strong> Пользователь может в любой момент изменить настройки своего браузера таким образом, чтобы все файлы "сookie" блокировались, или чтобы осуществлялось оповещение об их отправке. При этом Пользователь должен понимать, что в таком случае, некоторые функции и сервисы веб-сайта могут перестать функционировать или начать работать не корректно.
                </li>
                <li>
                    <strong>4.4</strong> Категории субъектов обработки персональных данных: пользователи (покупатели сервисов), потенциальные Пользователи и их представители; другие посетители веб-сайта.
                </li>
                <li>
                    <strong>4.5</strong> Для достижения целей обработки персональных данных, указанных в Политике конфиденциальности, Оператор в установленных целях вправе передавать персональные данные с соблюдением требований законодательства Российской Федерации перечисленным ниже третьим лицам:
                    <ul>
                        <li>
                            <strong>4.5.1</strong> ООО «Яндекс» (ОГРН: 1027700229193; ИНН: 7736207543; адрес: 119021, г. Москва, ул. Льва Толстого, д. 16). «Яндекс Метрика» – сервис веб-аналитики. Политика конфиденциальности опубликована в сети «Интернет» по постоянному URL-адресу: <a href="https://yandex.ru/legal/confidential/" target="_blank">https://yandex.ru/legal/confidential/</a>.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>4.6</strong> Оператор не осуществляет обработку персональных данных, разрешенных для распространения, биометрических персональных данных и специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, интимной жизни, сведений о судимости.
                </li>
            </ol>
            <h3>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
            <ol>
                <li>
                    <strong>5.1</strong> Способы обработки персональных данных: автоматизированная обработка персональных данных с помощью ЭВМ, с получением и (или) передачей полученных данных с помощью сети «Интернет»: сбор, запись, систематизация, накопление, хранение на территории Российской Федерации до утраты правовых оснований обработки и в течение установленных нормативными документами сроков хранения, передача (предоставление, доступ), уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение персональных данных.
                </li>
                <li>
                    <strong>5.2 </strong>Обработка персональных данных Пользователя осуществляется Оператором законным способом, с помощью сети «Интернет» и информационных систем персональных данных, с использованием средств автоматизации, в течение срока, необходимого для достижения и выполнения целей обработки персональных данных.
                </li>
            </ol>
            <h3>6. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
            <ol>
                <li>
                    <strong>6.1</strong> Оператор обрабатывает персональные данные Пользователя в следующих целях: 
                    <ul>
                        <li>
                            - заключение, исполнение, прекращение гражданско-правового договора;
                        </li>
                        <li>
                            - регистрация на веб-сайте;
                        </li>
                        <li>
                            - оформление заказа на покупку подписки; 
                        </li>
                        <li>
                            - обработка заказа на покупку подписки;
                        </li>
                        <li>
                            - оплата (покупка) подписки;
                        </li>
                        <li>
                            - идентификация плательщика и платежа; 
                        </li>
                        <li>
                            - коммуникация и информирование, рассылка уведомлений рекламно-информационного характера (с помощью отправки электронного письма по адресу электронной почты, в целях исполнения своих обязательств в рамках заключенных договоров, проведения рекламных или маркетинговых акций, розыгрышей, конкурсов, опросов, отправки специальных предложений, информирования о новых продуктах, сервисах и функциях, проведения работ по улучшению качества обслуживания, удобства использования веб-сайта, оформления заказа на покупку подписки, обработки заказа на покупку подписки, оплаты стоимости подписки и обработки платежа, обработки и выполнения запросов, направления иных уведомлений, выполнения иных обязательств по договору, а также предоставления технической, информационной, иной эффективной клиентской поддержки);
                        </li>
                        <li>
                            - проведение статистических и иных исследований, на основе обезличенных данных, предоставление безопасного и удобного функционала по использованию веб-сайта, эффективное отображение информации, осуществление и (или) исполнение функций, полномочий и обязанностей, возложенных на Оператора законодательством Российской Федерации, выполнение иных обязательств.
                        </li>
                    </ul>
                </li>
            </ol>
            <h3>7. СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
            <ol>
                <li>
                    <strong>7.1</strong> Пользователь добровольно, по своей воле и в собственных интересах, дает (предоставляет) Оператору свое конкретное, предметное, информированное, сознательное и однозначное согласие с обработкой его персональных данных Оператором на условиях, изложенных в Политике конфиденциальности, следующим способом: активация специального флажка («чекбокса»): «Я ознакомлен (-на) и согласен (-на) с Публичной офертой и Политикой конфиденциальности, даю свое согласие на обработку моих персональных данных» в контрольном пункте специальной формы на веб-сайте. Указанное действие имеет для сторон юридическую силу письменного согласия.
                </li>
                <li>
                    <strong>7.2</strong> Согласие Пользователя на обработку персональных данных, разрешенных для распространения, оформляется отдельно от других согласий на обработку персональных данных и предоставляется Оператору непосредственно. При обработке персональных данных, разрешенных для распространения, Оператором соблюдаются условия и ограничения, предусмотренные ст. 10.1 Федерального закона «О персональных данных» №152-ФЗ от 27.07.2006.
                </li>
            </ol>
            <h3>8. УСЛОВИЯ И ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
            <ol>
                <li>
                    <strong>8.1</strong> Обработка персональных данных Пользователя осуществляется только с согласия Пользователя на обработку его персональных данных, предоставленного установленным способом и в целях, определенных Политикой конфиденциальности, с учетом исключений, установленных законодательством Российской Федерации.
                </li>
                <li>
                    <strong>8.2</strong> В отношении персональных данных Пользователя сохраняется полная конфиденциальность информации, кроме случаев добровольного предоставления Пользователем согласия на обработку персональных данных, в том числе разрешенных для распространения неограниченному кругу лиц, и фактического предоставления персональных данных о себе.
                </li>
                <li>
                    <strong>8.3</strong> По общему правилу Оператор не раскрывает третьим лицам и не распространяет персональные данные без согласия Пользователя, кроме случаев, определенных законодательством Российской Федерации и Политикой конфиденциальности, а в случае передачи персональных данных третьим лицам, соблюдает положения Политики конфиденциальности и принимает меры к сохранности персональных данных.
                </li>
                <li>
                    <strong>8.4</strong> Оператор вправе передать персональные данные Пользователя третьим лицам в следующих случаях: Пользователь выразил свое согласие на обработку персональных данных; передача персональных данных необходима для достижения целей, определенных Политикой конфиденциальности, а также для выполнения Оператором своих обязательств перед Пользователем; передача персональных данных предусмотрена законодательством Российской Федерации; в целях защиты прав и законных интересов Оператора.
                </li>
                <li>
                    <strong>8.5</strong> Вся информация, которая собирается иными сервисами, в том числе интернет-эквайрингом, платежными системами, средствами связи и другими поставщиками услуг, хранится и обрабатывается указанными лицами (операторами) в соответствии с их правилами и политиками. Пользователь обязан самостоятельно ознакомиться с указанными документами третьих лиц (иных операторов). 
                </li>
                <li>
                    <strong>8.6</strong> Оператор не несет ответственность за действия третьих лиц, которые являются операторами и осуществляют самостоятельную обработку персональных данных (за исключением случаев, когда Оператор направил таким лицам соответствующее поручение в установленном порядке).
                </li>
                <li>
                    <strong>8.7 </strong>Обработка персональных данных Пользователя организована Операторам на принципах:
                    <ul>
                        <li>
                            <strong>8.7.1</strong> Законность целей и способов обработки персональных данных, добросовестность и справедливость деятельности Оператора. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.
                        </li>
                        <li>
                            <strong>8.7.2</strong> Достоверность персональных данных, их достаточность для целей обработки, недопустимость сбора персональных данных, избыточных по отношению к целям, заявленным при сборе персональных данных.
                        </li>
                        <li>
                            <strong>8.7.3</strong> Сбор и обработка только тех персональных данных, которые отвечают целям их сбора и обработки. 
                        </li>
                        <li>
                            <strong>8.7.4</strong> Соответствие содержания и объема персональных данных, которые собираются и обрабатываются, заявленным целям обработки.
                        </li>
                        <li>
                            <strong>8.7.5</strong> Не допускается избыточность обрабатываемых персональных данных по отношению к заявленным целям их обработки.
                        </li>
                        <li>
                            <strong>8.7.6 </strong>Недопустимость объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.
                        </li>
                        <li>
                            <strong>8.7.7</strong> При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Оператор принимает необходимые меры и/или обеспечивает их принятие по удалению или уточнению неполных или неточных данных.
                        </li>
                        <li>
                            <strong>8.7.8</strong> Хранение персональных данных осуществляется в форме, позволяющей определить Пользователя, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем, по которому является Пользователь.
                        </li>
                        <li>
                            <strong>8.7.9</strong> Обрабатываемые персональные данные уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>8.8</strong> Персональные данные предоставляются (собираются) непосредственно от Пользователя как субъекта персональных данных, или уполномоченного им лица в порядке, установленном законодательством Российской Федерации, если иное не предусмотрено федеральными законами.
                </li>
                <li>
                    <strong>8.9</strong> Пользователь самостоятельно принимает решение о предоставлении либо непредоставлении своих персональных данных, и дает согласие на их обработку Оператором добровольно и свободно, по своей воле и в собственных интересах.
                </li>
            </ol>
            <h3>9. ПРАВА И ОБЯЗАННОСТИ ОПЕРАТОРА</h3>
            <ol>
                <li>
                    <strong>9.1</strong> Оператор имеет право:
                    <ul class="style-disc">
                        <li>
                            предоставлять персональные данные Пользователя третьим лицам, если это предусмотрено законодательством Российской Федерации (налоговые, правоохранительные органы);
                        </li>
                        <li>
                            отказывать в предоставлении персональных данных в предусмотренных законодательством Российской Федерации случаях;
                        </li>
                        <li>
                            использовать персональные данные Пользователя без его согласия, в предусмотренных законодательством Российской Федерации случаях и в оправданном объеме;
                        </li>
                        <li>
                            получать от Пользователя достоверные информацию и (или) документы, содержащие персональные данные;
                        </li>
                        <li>
                            в случае отзыва Пользователем согласия на обработку персональных данных, а также, направления заявления (иного обращения) с требованием о прекращении обработки персональных данных, Оператор вправе продолжить обработку персональных данных без согласия Пользователя при наличии оснований, указанных в Федеральном законе Российской Федерации «О персональных данных» No152- ФЗ от 27.07.2006;
                        </li>
                        <li>
                            самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Федеральным законом Российской «О персональных данных» Федерации No152-ФЗ от 27.07.2006 и принятыми в соответствии с ним нормативно-правовыми актами, если иное не предусмотрено федеральными законами;
                        </li>
                        <li>
                            реализовать иные права, предоставленные ему законодательством Российской Федерации.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>9.2</strong> Оператор обязуется:
                    <ul class="style-disc">
                        <li>
                            предоставлять Пользователю по его просьбе информацию, касающуюся обработки его персональных данных;
                        </li>
                        <li>
                            организовывать обработку персональных данных в порядке, установленном действующим законодательством Российской Федерации;
                        </li>
                        <li>
                            отвечать на обращения и запросы Пользователей и их законных представителей в течение 10 (десяти) рабочих дней с момента обращения либо получения Оператором запроса Пользователя или его представителя. Указанный срок может быть продлен, но не более чем на 5 (пять) рабочих дней в случае направления Оператором в адрес субъекта персональных данных мотивированного уведомления с указанием причин продления срока предоставления запрашиваемой информации;
                        </li>
                        <li>
                            сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа необходимую информацию в течение 10 (десяти) рабочих дней с даты получения такого запроса. Указанный срок может быть продлен, но не более чем на 5 (пять) рабочих дней в случае 
                            направления Оператором в адрес уполномоченного органа по защите прав субъектов персональных данных мотивированного уведомления с указанием причин продления срока предоставления запрашиваемой информации;
                        </li>
                        <li>
                            публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике конфиденциальности в отношении обработки персональных данных на каждой странице веб-сайта, на которой осуществляется сбор персональных данных;
                        </li>
                        <li>
                            обеспечить каждому Пользователю возможность ознакомления с документами и материалам, содержащими его персональные данные, если иное не предусмотрено законодательством Российской Федерации;
                        </li>
                        <li>
                            принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных;
                        </li>
                        <li>
                            прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку и уничтожить персональные данные в порядке и случаях, предусмотренных Федеральным законом Российской Федерации «О персональных данных» No152-ФЗ от 27.07.2006;
                        </li>
                        <li>
                            исполнять иные обязанности, предусмотренные законодательством РФ.
                        </li>
                    </ul>
                </li>
            </ol>
            <h3>10. ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ</h3>
            <ol>
                <li>
                    <strong>10.1</strong> Пользователь имеет право:
                    <ul class="style-disc">
                        <li>
                            получить в течение 10 (десяти) рабочих дней с момента обращения информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных федеральными законами, а в отдельных случаях в срок не более 15 (пятнадцати) рабочих дней. Сведения предоставляются Пользователю Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим Пользователям, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен Федеральным законом Российской Федерации «О персональных данных» No152- ФЗ от 27.07.2006;
                        </li>
                        <li>
                            требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;
                        </li>
                        <li>
                            требовать перечень своих персональных данных, обрабатываемых Оператором и информацию об источниках их получения;
                        </li>
                        <li>
                            получать информацию о сроках обработки своих персональных данных, в том числе о сроках их хранения;
                        </li>
                        <li>
                            требовать уведомления всех лиц, которым ранее были сообщены неверные или неполные его персональные данные, обо всех произведенных в них исключениях, изменениях или дополнениях;
                        </li>
                        <li>
                            выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на рынке товаров, работ и услуг;
                        </li>
                        <li>
                            на отзыв согласия на обработку персональных данных, а также, на направление требования о прекращении обработки персональных данных;
                        </li>
                        <li>
                            обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных;
                        </li>
                        <li>
                            реализовать иные права, предоставленные ему законодательством Российской Федерации.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>10.2</strong> Пользователь обязуется:
                    <ul class="style-disc">
                        <li>
                            в случае принятия добровольного решения о передаче своих персональных данных, передать достоверные, необходимые для достижения целей обработки персональные данные, а также, при необходимости подтверждать достоверность персональных данных предъявлением оригиналов документов;
                        </li>
                        <li>
                            сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных;
                        </li>
                        <li>
                            исполнять иные обязанности, предусмотренные законодательством Российской Федерации.
                        </li>
                    </ul>
                </li>
            </ol>
            <h3>11. ТРАНСГРАНИЧНАЯ ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
            <ol>
                <li>
                    <strong>11.1</strong> По общему правилу Оператор не использует трансграничную передачу персональных данных. В случае, если трансграничная передача персональных данных будет совершена, Оператор обязуется уведомить Пользователя о данном факте в порядке и сроки, установленные федеральными законами Российской Федерации.
                </li>
                <li>
                    <strong>11.2</strong> Под трансграничной передачей данных понимается передача данных третьим лицам как в странах с надлежащим уровнем защиты данных, так и не относящихся к таким странам. Необходимый уровень защиты персональных данных обеспечивается Оператором путем соблюдения условий, указанных в Политике конфиденциальности.
                </li>
                <li>
                    <strong>11.3</strong> Оператор доначала осуществления деятельности потрансграничной передаче персональных данных обязан уведомить уполномоченный орган по защите прав субъектов персональных данных о своем намерении осуществлять трансграничную передачу персональных данных (такое уведомление направляется отдельно от уведомления о намерении осуществлять обработку персональных данных) и убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надлежащая защита прав субъектов персональных данных.
                </li>
                <li>
                    <strong>11.4</strong> Оператор до подачи уведомления в уполномоченный орган по защите прав субъектов персональных данных, обязан получить от органов власти иностранного государства, иностранных физических лиц, иностранных юридических лиц, которым планируется трансграничная передача персональных данных, соответствующие сведения.
                </li>
            </ol>
            <h3>12. ПЕРСОНАЛЬНЫЕ ДАННЫЕ НЕСОВЕРШЕННОЛЕТНИХ ЛИЦ И ЗАЩИТА ИНТЕРЕСОВ ДЕТЕЙ</h3>
            <ol>
                <li>
                    <strong>12.1</strong> Оператор призывает несовершеннолетних лиц не использовать веб-сайт, а также не предоставлять какие-либо сведения о себе, в связи с использованием веб-сайта, без разрешения и участия родителей либо иных законных представителей. Условия использования веб-сайта, изложенные в Политике конфиденциальности, обязывают родителей либо иных законных представителей обеспечить постоянный и непосредственный контроль за всеми действиями своих несовершеннолетних детей при использовании веб-сайта.
                </li>
                <li>
                    <strong>12.2</strong> Оператор не осуществляет намеренно обработку персональных данных несовершеннолетних лиц, без согласия на это их законных представителей и требует использовать веб-сайт исключительно лицам, достигшим 18 лет. Ответственность за действия несовершеннолетних лиц по использованию веб-сайта, включая оформление заказов и подачу каких-либо запросов на веб-сайте, лежит на законных представителях таких несовершеннолетних лиц.
                </li>
                <li>
                    <strong>12.3</strong> Оператор предпринимает все возможные и зависящие от него меры во избежание использования веб-сайта несовершеннолетним лицом без согласия законного представителя, и, если Оператору станет известно, что он получил персональные данные несовершеннолетнего лица без согласия его законных представителей, Оператор предпримет все возможные меры чтобы обработка персональных данных несовершеннолетнего лица была немедленно прекращена.
                </li>
            </ol>
            <h3>13. МЕРЫ ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
            <ol>
                <li>
                    <strong>13.1</strong> Безопасность персональных данных Пользователя, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения требований ст. 19 Федерального закона Российской Федерации «О персональных данных» No152-ФЗ от 27.07.2006 и иных нормативно-правовых актов Российской Федерации, принятых на его исполнение.
                </li>
                <li>
                    <strong>13.2</strong> Оператор обеспечивает сохранность и безопасность персональных данных Пользователя, принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц, в частности, применением следующих способов:
                </li>
                <li>
                    <strong>13.2.1</strong> Оценка эффективности мер по обеспечению безопасности персональных данных до начала использования таких мер.
                </li>
                <li>
                    <strong>13.2.2</strong> Обнаружение фактов несанкционированного доступа к персональным данным и принятие мер по их устранению и недопущению повторного несанкционированного доступа.
                </li>
                <li>
                    <strong>13.2.3</strong> Восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним.
                </li>
                <li>
                    <strong>13.2.4</strong> Установление правил доступа к персональным данным, обрабатываемым в информационной системе персональных данных.
                </li>
                <li>
                    <strong>13.2.5</strong> Обеспечение регистрации и учета всех действий, совершаемых с персональными данными в информационной системе персональных данных.
                </li>
                <li>
                    <strong>13.2.6</strong> Проверка наличия в договорах, заключаемых Оператором с третьими лицами, пунктов об обеспечении конфиденциальности персональных данных и включением их, при необходимости, в такие договоры.
                </li>
                <li>
                    <strong>13.2.7</strong> Контроль за принимаемыми мерами по обеспечению безопасности персональных данных и уровня защищенности информационных систем персональных данных.
                </li>
                <li>
                    <strong>13.3</strong> Третьи лица, получившие доступ к персональным данным по поручению Оператора, обязуются перед Оператором принимать необходимые организационные и технические меры к обеспечению конфиденциальности такой информации на своем персональном устройстве, с которого осуществляется обработка персональных данных.
                </li>
                <li>
                    <strong>13.4</strong> Взаимодействие с федеральными органами исполнительной власти по вопросам обработки и защиты персональных данных Пользователя осуществляется в рамках законодательства Российской Федерации.
                </li>
            </ol>
            <h3>14. КОНФИДЕНЦИАЛЬНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
            <ol>
                <li>
                    <strong>14.1</strong> Оператор и иные уполномоченные им лица, получившие доступ к персональным данным Пользователя, обязаны не раскрывать и не распространять персональные данные третьим лицам без согласия Пользователя, если иное не предусмотрено федеральными законами, а также принять все возможные меры в целях обеспечения защиты персональных данных от несанкционированного доступа к ним третьих лиц.
                </li>
                <li>
                    <strong>14.2</strong> В случае установления факта неправомерной или случайной передачи (предоставления, распространения, доступа) персональных данных третьим лицам, повлекшей нарушение прав Пользователя, с момента выявления такого инцидента Оператором, уполномоченным органом по защите прав субъектов персональных данных или иным заинтересованным лицом, уведомляет уполномоченный орган по защите прав субъектов персональных данных:
                </li>
                <li>
                    <strong>14.2.1</strong> В течение 24 (двадцати четырех) часов о произошедшем инциденте, о предполагаемых причинах, повлекших нарушение прав Пользователя, и предполагаемом вреде, нанесенном правам Пользователя, о принятых мерах по устранению последствий соответствующего инцидента, а также предоставляет сведения о лице, уполномоченном Оператором на взаимодействие с уполномоченным органом по защите прав Пользователя, по вопросам, связанным с выявленным инцидентом;
                </li>
                <li>
                    <strong>14.2.2</strong> В течение 72 (семидесяти двух) часов о результатах внутреннего расследования выявленного инцидента, а также предоставляет сведения о лицах, действия которых стали причиной выявленного инцидента (при наличии).
                </li>
            </ol>
            <h3>15. ПРЕКРАЩЕНИЕ ОБРАБОТКИ И УНИЧТОЖЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
            <ol>
                <li>
                    <strong>15.1</strong> Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия согласия Пользователя, отзыв согласия Пользователя, выявление факта неправомерной обработки персональных данных, ликвидация или реорганизации Оператора.
                </li>
                <li>
                    <strong>15.2</strong> Пользователь вправе в любой момент отозвать свое согласие на обработку персональных данных, в т. ч. разрешенных для распространения, путем подачи Оператору отзыва в виде электронного документа на адрес электронной почты: <a href="mailto:Ya.tsokunova@eastragene.com">Ya.tsokunova&#64;eastragene.com</a> с темой письма: «Отзыв согласия на обработку персональных данных». Согласие Пользователя на обработку персональных данных прекращает свое действие с момента поступления Оператору отзыва согласия.
                </li>
                <li>
                    <strong>15.3</strong> В случае выявления неточных персональных данных, Пользователь обязан актуализировать их самостоятельно, путем подачи Оператору требования об уточнении персональных данных в виде электронного документа на адрес электронной почты: <a href="mailto:Ya.tsokunova@eastragene.com">Ya.tsokunova&#64;eastragene.com</a> с темой письма: «Актуализация персональных данных».
                </li>
                <li>
                    <strong>15.4</strong> В случае выявления неточных персональных данных, Оператор по требованию Пользователя обязан осуществить блокирование персональных данных, относящихся к Пользователю, с момента такого требования и на период проверки, если блокирование персональных данных не нарушает права и законные интересы Пользователя или третьих лиц.
                </li>
                <li>
                    <strong>15.5</strong> В случае подтверждения факта неточности персональных данных, Оператор обязан уточнить персональные данные в течение 7 (семи) календарных дней со дня представления Пользователем уточненных (актуализированных) персональных данных, и снять блокирование персональных данных Пользователя.
                </li>
                <li>
                    <strong>15.6</strong> Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте персональных данных без согласия последнего, несут ответственность в соответствии с законодательством Российской Федерации.
                </li>
                <li>
                    <strong>15.7</strong> В случае достижения цели обработки персональных данных Оператор прекращает обработку персональных данных (или обеспечивает ее прекращение) и уничтожает персональные данные (или обеспечивает их уничтожение) в срок, не превышающий 30 (тридцати) календарных дней с даты достижения цели обработки персональных данных.
                </li>
                <li>
                    <strong>15.8</strong> Персональные данные на электронных носителях уничтожаются путем стирания и (или) форматирования носителя, уничтожение документов (материальных носителей), содержащих персональные данные, производится путем сожжения, дробления (измельчения), химического разложения.
                </li>
                <li>
                    <strong>15.9</strong> По результатам уничтожения персональных данных, Оператором составляется Акт об уничтожении персональных данных и (или) обеспечивается и сохраняется выгрузка из журнала регистрации событий в информационной системе (бухгалтерской или кадровой программы, корпоративного веб-сайта, другой подобной программы для ЭВМ или иного источника сбора и хранения данных).
                </li>
                <li>
                    <strong>15.10</strong> В случае выявления неправомерной обработки персональных данных, Оператор в срок, не превышающий 3 (трех) календарных дней со дня выявления такого факта, обязан прекратить неправомерную обработку персональных данных.
                </li>
                <li>
                    <strong>15.11</strong> В случае, если обеспечить правомерность обработки персональных данных невозможно, Оператор в срок, не превышающий 10 (десяти) календарных дней со дня выявления неправомерной обработки персональных данных, обязан уничтожить такие персональные данные. Об устранении допущенных нарушений или об уничтожении персональных данных Оператор обязан уведомить Пользователя.
                </li>
                <li>
                    <strong>15.12</strong> Оператор вправе продолжить использовать персональные данные о Пользователе по итогу рассмотрения отзыва согласия на обработку персональных данных обеспечив обезличивание такой информации в случаях, установленных федеральными законами.
                </li>
            </ol>
            <h3>16. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h3>
            <ol>
                <li>
                    <strong>16.1</strong> Оператор в течение 30 (тридцати) календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах ее рассмотрения. Указанный срок может быть продлен, но не более чем на 15 (пятнадцать) календарных дней в случае направления Оператором в адрес Пользователя мотивированного уведомления с указанием причин продления срока предоставления ответа на претензию.
                </li>
                <li>
                    <strong>16.2</strong> При недостижении соглашения между сторонами, спор передается на рассмотрение в судебный орган, согласно с установленными правилами подсудности, в соответствии с действующим законодательством Российской Федерации.
                </li>
            </ol>
            <h3>17. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
            <ol>
                <li>
                    <strong>17.1</strong> Политика конфиденциальности распространяется на все правоотношения между Оператором и Пользователем в области сбора, обработки и защиты персональных данных и применяется в отношении всех собираемых и обрабатываемых персональных данных. Все вопросы, связанные со сбором и обработкой персональных данных, не урегулированные Политикой конфиденциальности, разрешаются в соответствии с законодательством Российской Федерации в области сбора, обработки и защиты персональных данных.
                </li>
                <li>
                    <strong>17.2</strong> Политика конфиденциальности действует бессрочно, до момента принятия новой редакции. Изменения (дополнения) подлежат внесению в Политику конфиденциальности в случае изменения объема, способа или порядка обработки персональных данных, появления новых законодательных актов и специальных нормативных документов по обработке и защите персональных данных, и вносятся путем внесения изменений непосредственно в Политику конфиденциальности с последующей публикацией новой редакции и указанием даты публикации.
                </li>
                <li>
                    <strong>17.3</strong> Пользователь вправе в полном объеме реализовать все свои права, как субъект персональных данных, и получить разъяснения по интересующим вопросам, касающимся сбора, обработки и защиты его персональных данных, обратившись к Оператору с помощью адреса электронной почты: <a href="mailto:Ya.tsokunova@eastragene.com">Ya.tsokunova&#64;eastragene.com</a>.
                </li>
                <li>
                    <strong>17.4</strong> Подлинный экземпляр Политики конфиденциальности хранится по месту нахождения Оператора.
                </li>
                <li>
                    <strong>17.5</strong> Доступ к Политике конфиденциальности открыт для Пользователя круглосуточно и без необходимости выполнения дополнительных условий, за исключением случаев, когда веб-сайт не доступен по техническим причинам.
                </li>
                <li>
                    <strong>17.6</strong> Политика конфиденциальности составлена на русском языке в соответствии с законодательством Российской Федерации. Во исполнение требований п. 2 ст. 18.1 Федерального закона Российской Федерации «О персональных данных» No152-ФЗ от 27.07.2006, Политика конфиденциальности публикуется на веб-сайте в информационно-телекоммуникационной сети общего пользования «Интернет» по постоянному URL-адресу: <a href="https://eastragene.com" target="_blank">eastragene.com</a> в разделе «Политика конфиденциальности» с целью обеспечения неограниченного и свободного доступа к ней для всех Пользователей.
                </li>
            </ol>
            <h3>18. РЕКВИЗИТЫ ОПЕРАТОРА</h3>
            <div class="table-container">
                <table class="bordered-table">
                    <tr>
                        <td>Наименование:</td>
                        <td>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ВЕДА»</td>
                    </tr>
                    <tr>
                        <td>ОГРН:</td>
                        <td>1237700856887</td>
                    </tr>
                    <tr>
                        <td>ИНН:</td>
                        <td>7743433550</td>
                    </tr>
                    <tr>
                        <td>Юридический адрес:</td>
                        <td>125212, г. Москва, ул. Адмирала Макарова, д. 8, стр. 1, помещ. 6/1</td>
                    </tr>
                    <tr>
                        <td>E-mail:</td>
                        <td><a href="mailto:Ya.tsokunova@eastragene.com">Ya.tsokunova&#64;eastragene.com</a></td>
                    </tr>
                </table>
            </div>
            <app-footer />
        </div>
        
    </div>
</main>
