import { Component, Input, SimpleChanges, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BlogService } from '@app/_services/blog.service';
import { BlogPostList } from '@app/_models';
import { FooterComponent } from '@app/_components/footer/footer.component';

@Component({
  selector: 'app-blog',
  standalone: true,
  imports: [RouterModule, CommonModule, FooterComponent],
  templateUrl: './blog.component.html',
  styleUrl: './blog.component.sass'
})
export class BlogComponent {
  @Input() tag_id?: string;
  blogService = inject(BlogService);

  postList: BlogPostList | null = null;
  loading: boolean = false;

  loadPosts(id?: string) {
    console.log('load posts', id);
    this.loading = true;
    this.blogService.getPostList(id).subscribe({
      next: (r) => {
        this.loading = false;
        this.postList = r;
        console.log(this.postList);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
      if (changes['tag_id'] && !this.loading) {
        if (changes['tag_id'].currentValue)
          this.loadPosts(changes['tag_id'].currentValue.toString());
        else this.loadPosts();
      }
    }
}
