<main class="account account-page edit-page only-back">
    <header>
        <a href="#" class="logo"><img src="../assets/images/account_logo.svg" ></a>
        <div class="back-wrapper">
            <a [routerLink]="['/account/edit']" class="back-link"><span>&nbsp;</span></a>
        </div>
    </header>

    <section [ngClass]="{'subscriptions-plans': true, loading: loading}">
        <h2>Варианты подписки</h2>
        <form class="plan-form" [formGroup]="form" (submit)="submit()">
            <ul class="subscriptions-list">
                <li *ngFor="let plan of plans">
                    <input type="radio" [value]="plan.code" formControlName="plan" [id]="plan.id" />
                    <label [for]="plan.id">
                        <p *ngIf="plan.badge" class="label-sale">{{ plan.badge }}</p>
                        <p *ngIf="plan.trial_period" class="label-trial"><span>{{ plan.trial_period.trial_period_title }}</span></p>
                        <p class="s-name">{{ plan.title }}</p>
                        <p>
                            <span class="s-period">{{ plan.price_title }}</span>
                            <span class="price">{{ plan.price_subtitle }}</span>
                        </p>
                    </label>
                </li>
            </ul>
            <p [ngClass]="{'message error': true, 'shown': form.invalid && form.touched}">
                Выберите один из вариантов.
            </p>
            <div class="actions-wrapper">
                <button type="submit" class="btn-black"><span >Продолжить</span></button>
            </div>
        </form>
        <div class="options-list promocode">
            <h2>Промокод</h2>
            <form [formGroup]="offerForm" (submit)="offerSubmit()">
                <div class="field">
                    <div class="input-box">
                        <input type="text" formControlName="code" />
                        <p class="message success shown">{{ offerLabel }}</p>
                        <span *ngIf="offerLabel && !loading"class="include">&nbsp;</span>
                    </div>
                    <div class="control">
                        <button type="submit" class="dark-btn">
                            Применить
                        </button>
                    </div>
                </div>
                <p *ngIf="offerForm.invalid && offerForm.touched" class="message error shown">
                    Введите промокод.
                </p>
                <p *ngFor="let error of serverErrors" class="message error shown">{{ error }}</p>
            </form>
        </div>
        <div>
            <table class="options-list">
                <tr>
                    <td></td>
                    <td><p>Бесплатно</p></td>
                    <td><p class="dark-label">Подписка</p></td>
                </tr>
                <tr>
                    <td class="with-icon cycle-track">
                        <p class="service-name">Цикл-трекер</p>
                        <p class="service-options">Комфортный интерфейс для отслеживания циклов</p>
                    </td>
                    <td><span class="include">&nbsp;</span></td>
                    <td><span class="include">&nbsp;</span></td>
                </tr>
                <tr>
                    <td class="with-icon statistic">
                        <p class="service-name">Статистика</p>
                        <p class="service-options">Подробная информация о текущем дне фазы и полная статистика о цикле</p>
                    </td>
                    <td><span class="not-include">&nbsp;</span></td>
                    <td><span class="include">&nbsp;</span></td>
                </tr>
                <tr>
                    <td class="with-icon food">
                        <p class="service-name">Питание</p>
                        <p class="service-options">Авторская программа питания с ежемесячным обновлением полезных рецептов</p>
                    </td>
                    <td><span class="not-include">&nbsp;</span></td>
                    <td><span class="include">&nbsp;</span></td>
                </tr>
                <tr>
                    <td class="with-icon activity">
                        <p class="service-name">Активность</p>
                        <p class="service-options">Индивидуально подобранная программа тренировок на каждую фазу цикла с ежемесячным обновлением</p>
                    </td>
                    <td><span class="not-include">&nbsp;</span></td>
                    <td><span class="include">&nbsp;</span></td>
                </tr>
                <tr>
                    <td class="with-icon moon">
                        <p class="service-name">Лунный календарь</p>
                        <p class="service-options">Астрологические календарь на каждый день с подсказками по красоте и здоровью</p>
                    </td>
                    <td><span class="not-include">&nbsp;</span></td>
                    <td><span class="include">&nbsp;</span></td>
                </tr>
                <tr >
                    <td class="with-icon blog">
                        <p class="service-name">Блог</p>
                        <p class="service-options">Статьи и советы от именитых экспертов и основателей wellness проектов</p>
                    </td>
                    <td><span class="not-include">&nbsp;</span></td>
                    <td><span class="include">&nbsp;</span></td>
                </tr>
            </table>
        </div>
        <div class="bottom-container">
            <a href="#" class="logo"><img src="../assets/images/mobile_logo.svg" ></a>
            <app-footer />
            
        </div>
    </section>
    <app-subscription-plans></app-subscription-plans>
</main>


