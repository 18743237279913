import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { SubscriptionPlansComponent } from '@app/_components/subscription-plans/subscription-plans.component';
import { AccountService } from '@app/_services/account.service';
import { SubscriptionPlan, CustomerInfo } from '@app/_models';
import { FooterComponent } from '@app/_components/footer/footer.component';

@Component({
  selector: 'app-subscribe',
  standalone: true,
  imports: [RouterModule, ReactiveFormsModule, SubscriptionPlansComponent, CommonModule, FooterComponent],
  templateUrl: './subscribe.component.html',
  styleUrl: './subscribe.component.sass'
})
export class SubscribeComponent {
  accountService = inject(AccountService);
  router = inject(Router);

  plans: SubscriptionPlan[] = [];
  currentPlan: string = '';
  loading: boolean = true;
  serverErrors: string[] = [];
  form = new FormGroup({
    plan: new FormControl('', [Validators.required])
  });
  offerForm = new FormGroup({
    code: new FormControl('', [Validators.required])
  });
  offerLabel: string = '';


  ngOnInit() {
    this.initPlans();
  }

  initPlans() {
    this.accountService.getSubscriptionsList().subscribe({
      next: (r) => {
        this.loading = false;
        this.plans = r;
      }
    });
    this.accountService.getCustomerInfo().subscribe({
      next: (r) => {
        if (r.premium_subscription !== null && r.premium_subscription.subscription_type) {
          this.currentPlan = r.premium_subscription.subscription_type.code;
          this.form.controls['plan'].setValue(this.currentPlan);
        }
        if (r.subscription_offer) {
          this.offerForm.setValue({ code: r.subscription_offer.code });
          this.offerLabel = r.subscription_offer.label;
        }

      }
    });
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAsTouched();
      return false;
    }
    this.loading = true;
    if (this.form.value['plan']) {
      this.accountService.activateSubscription(this.form.value['plan']).subscribe({
        next: (r) => {
          if (r.confirmation_url) {
            window.location.href=r.confirmation_url;
          } else {
            this.loading = false;
            this.router.navigate(['/account/edit']);
          }
        }
      });
    }
    return true;
  }

  offerSubmit() {
    if (this.offerForm.invalid) {
      this.offerForm.markAsTouched();
      return false;
    }
    this.loading = true;
    this.serverErrors = [];
    if (this.offerForm.value['code']) {
      this.accountService.activateOffer(this.offerForm.value['code']).subscribe({
        next: (r) => {
          this.loading = false;
          this.initPlans();
        },
        error: (e) => {
          this.loading = false;
          if (e.error?.errors?.length) {
            const errors = e.error.errors;
            for (let error of errors) {
              this.serverErrors.push(error.detail);
            }
          }
        }
      });
    }
    return true;
  }
}
