<main class="main blog-page">
    <div class="main-wrapper">
        <header>
            <a [routerLink]="['/']" class="logo">
                <img src="../assets/images/account_logo.svg" >
            </a>

            <div class="links">
                <a [routerLink]="['/blog']"class="header-link blog-link">
                    <span><img src="../assets/images/blog_dark.svg"></span>
                    <span>Блог</span>
                </a>
                <a [routerLink]="['/login']"class="header-link">
                    <span><img src="../assets/images/account_dark.svg" ></span>
                </a>
            </div>
        </header>
        <div class="content blog-content">
            <h1>Блог</h1>
            <div [ngClass]="{'blog-container': true, 'loading': loading}">
                <ng-container *ngIf="postList">
                    <ul class="tag-list">
                        <li>
                            <a [ngClass]="{ 'active': !tag_id }" [routerLink]="['/blog']">Все</a>
                        </li>
                        <li *ngFor="let tag of postList.tags">
                            <a [ngClass]="{ 'active': tag_id == tag.id }" [routerLink]="['/blog', { tag_id: tag.id}]">{{ tag.title }}</a>
                        </li>
                    </ul>
                    <ul class="post-list">
                        <li *ngFor="let post of postList.posts">
                            <a [routerLink]="['/blog/post', post.id]">
                                <img src="{{ post.image }}" />
                            </a>
                            <a class="post-info" [routerLink]="['/blog/post', post.id]">
                                <p class="post-title">{{ post.title }}</p>
                                <span class="readtime">{{ post.read_time }}</span>
                            </a>
                        </li>
                    </ul>
                </ng-container>
            </div>
            <app-footer />
        </div>
    </div>
</main>
