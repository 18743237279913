<main class="main blog-page">
    <div class="main-wrapper">
        <header>
            <a [routerLink]="['/']" class="logo">
                <img src="../assets/images/account_logo.svg" >
            </a>

            <div class="links">
                <a [routerLink]="['/blog']"class="header-link blog-link">
                    <span><img src="../assets/images/blog_dark.svg"></span>
                    <span>Блог</span>
                </a>
                <a [routerLink]="['/login']"class="header-link">
                    <span><img src="../assets/images/account_dark.svg" ></span>
                </a>
            </div>
        </header>
        <div class="content blog-content">
            <div *ngIf="post" [ngClass]="{'blog-container': true, 'loading': loading}">
                <ul class="post-tags">
                    <li *ngFor="let tag of post.tags">
                        <a [routerLink]="['/blog', { tag_id: tag.id}]">{{ tag.title }}</a>
                    </li>
                </ul>
                <h1>{{ post.title }}</h1>
                <p class="created-at">{{ createdAt }} &bull; {{ post.read_time }}</p>
                <p class="author-info">
                    <img class="author-image" src="{{ post.author.image }}" />
                    <span class="author-name">{{ post.author.name }}</span>
                </p>
                <div class="post-image">
                    <img src="{{ post.image }}" />
                </div>
                <div class="post-content" [innerHTML]="post.content"></div>
            </div>
            <app-footer />
        </div>
    </div>
</main>
