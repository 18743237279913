import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BlogPostList, BlogPost } from '@app/_models';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  private http = inject(HttpClient);

  constructor() { }

  getPostList(id?: string) {
    if (typeof id !== 'undefined') {
      const params = new HttpParams().set("tag_id", id);
      console.log(params);
      return this.http.get<BlogPostList>(`${environment.apiUrl}blog/post/list/`, {
        params: params
      });
    } else {
      return this.http.get<BlogPostList>(`${environment.apiUrl}blog/post/list/`);
    }
  }

  getPost(id: string) {
    console.log(id);

    const params = new HttpParams().set("id", id);
    console.log(params);
    return this.http.get<BlogPost>(`${environment.apiUrl}blog/post/`, {
      params: params
    });
  }

}
