import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BlogService } from '@app/_services/blog.service';
import { BlogPost } from '@app/_models';
import { FooterComponent } from '@app/_components/footer/footer.component';
import dayjs from 'dayjs';

@Component({
  selector: 'app-post',
  standalone: true,
  imports: [RouterModule, CommonModule, FooterComponent],
  templateUrl: './post.component.html',
  styleUrl: './post.component.sass'
})

export class PostComponent {
  @Input() id: string = '';
  blogService = inject(BlogService);
  createdAt: string = '';

  post: BlogPost | null = null;
  loading: boolean = false;

  ngOnInit() {
    this.loading = true;

    this.blogService.getPost(this.id).subscribe({
      next: (r) => {
        this.loading = false;
        this.post = r;
        this.createdAt = dayjs(r.created_at).format('DD.MM.YYYY');
        console.log(this.post);
      }
    });
  }
}
