<main class="main homepage">
    <div class="top-container">
        <header>
            <a [routerLink]="['/']" class="logo">
                <img src="../assets/images/logo_white.svg" >
            </a>
            <div class="links">
                <a [routerLink]="['/blog']"class="header-link blog-link">
                    <span><img src="../assets/images/blog.svg" ></span>
                    <span>Блог</span>
                </a>
                <a [routerLink]="['/login']"class="header-link"><span><img src="../assets/images/account.svg" ></span></a>
            </div>
        </header>
        <section class="main-banner">
            <picture>
                <source srcset="../assets/images/top-bannerMob.jpg" media="(max-width: 768px)" />
                <source srcset="../assets/images/top-bannerLg.jpg" media="(max-width: 1024px)" />
                <img src="../assets/images/top_banner_big.jpg" >
            </picture>
            <div class="main-banner-content">
                <h1>Ēastragene – в твоем ритме<br/> Приложение для достижения гармонии со своей женской природой</h1>
                <a class="download-to-phone" target="_blank" href="https://apps.apple.com/ru/app/%C4%93astragene/id6657984011"><span class="apple-icon">&nbsp;</span><span>Скачать на iPhone</span><img class="q-icon" src="../assets/images/qr.jpg" ></a>
                <ul class="tag-list">
                    <li>
                        <span class="icon cycle">&nbsp;</span>
                        <span>
                            Цикл-трекер
                        </span>
                    </li>
                    <li>
                        <span class="icon food">&nbsp;</span>
                        <span>
                            Питание
                        </span>
                    </li>
                    <li>
                        <span class="icon activity">&nbsp;</span>
                        <span>
                            Активность
                        </span>
                    </li>
                    <li>
                        <span class="icon moon">&nbsp;</span>
                        <span>
                            Лунный календарь
                        </span>
                    </li>
                    <li>
                        <span class="icon blog">&nbsp;</span>
                        <span>
                            Блог о женском здоровье
                        </span>
                    </li>
                </ul>
            </div>
        </section>
    </div>
    <div class="main-wrapper">
        <div class="content">
            <section class="phone-banner">
                <div class="conception-text">
                    <h2>Концепция<br/> Ēastragene</h2>
                    <p>
                        Мы создали приложение, которое поможет вам контролировать женский цикл и следовать полезному образу жизни без лишних усилий.
                    </p>
                    <p>
                        Метод Ēastragene опирается на особенности гормонального фона женщины: в каждой фазе цикла нам дана особенная сила, которую мы учим раскрывать и поддерживать на стабильном уровне с помощью питания, физической активности и научных рекомендаций.    
                    </p>
                    <p>
                        Сервис от лучших wellness-экспертов составляет для вас авторские рецепты, тренировки, астрологический компас и собирает статьи специалистов по здоровью, нутрициологии, спорту и красоте.
                    </p>
                    <ul class="tag-list mobile">
                        <li>
                            <span class="icon cycle">&nbsp;</span>
                            <span>
                                Цикл-трекер
                            </span>
                        </li>
                        <li>
                            <span class="icon food">&nbsp;</span>
                            <span>
                                Питание
                            </span>
                        </li>
                        <li>
                            <span class="icon activity">&nbsp;</span>
                            <span>
                                Активность
                            </span>
                        </li>
                        <li>
                            <span class="icon moon">&nbsp;</span>
                            <span>
                                Лунный календарь
                            </span>
                        </li>
                        <li>
                            <span class="icon blog">&nbsp;</span>
                            <span>
                                Блог о женском здоровье
                            </span>
                        </li>
                    </ul>
                    <a class="download-to-phone" target="_blank" href="https://apps.apple.com/ru/app/%C4%93astragene/id6657984011"><span class="apple-icon">&nbsp;</span><span>Скачать на iPhone</span><img class="q-icon" src="../assets/images/qr.jpg" ></a>
                </div>
                <div class="img-wrapper right"><img src="../assets/images/phone_img.png" ></div>
            </section>
            <section class="track-cycle">
                <h2>Трекер цикла</h2>
                <ul>
                    <li>
                        <div><img src="../assets/images/cycle1.jpg" ></div>
                        <p>
                            Календарь для отслеживания 
                            циклов поможет разобраться, 
                            что происходит в вашем
                            организме именно сегодня
                        </p>
                    </li>
                    <li>
                        <div><img src="../assets/images/cycle2.jpg" ></div>
                        <p>
                            Описание текущей фазы даст вам
                            научные объяснения
                            гормональных изменений и
                            подскажет на каких потребностях
                            стоит сфокусироваться
                        </p>
                    </li>
                    <li>
                        <div><img src="../assets/images/cycle3.jpg" ></div>
                        <p>
                            Статистический блок соберет информацию о предыдущих циклах и проверит, все ли было в порядке
                        </p>
                    </li>
                </ul> 

            </section>
            <section class="service-section">
                <ul class="items-list">
                    <li class="left-img">
                        <h2 class="mobile-heading">Питание</h2>
                        <img src="../assets/images/food-banner.jpg" >
                        <div>
                            <h2 class="desktop-heading">
                                Питание
                            </h2>
                            <p>
                                Мы строим мир, в котором питание перестанет быть проблемой для женщины, а будет источником ее сил и вдохновения. 
                            </p>
                            <p>
                                Приложение дает рекомендации по рациону на каждую фазу и никогда не забывает, что опора на циклы — ключ к получению максимальной пользы от еды.
                            </p>
                            <p>
                                Несложные рецепты занимают всего 15-30 минут, при этом заряжают оптимальной энергией и балансируют гормональный фон. 
                            </p>
                        </div>

                    </li>
                    <li class="right-img">
                        <h2 class="mobile-heading">
                            Активность
                        </h2>
                        <img src="../assets/images/activity-banner.jpg" >
                        <div>
                            <h2 class="desktop-heading">
                                Активность
                            </h2>
                            <p>
                                Движение — в удовольствие! Ēastragene отходит от концепции универсальных тренировок для всех: наша программа сфокусирована на особенностях женщины и учитывает ее анатомию.
                            </p>
                            <p>
                                Мы дадим вам понятные ориентиры по активностям и доступ к классным онлайн-занятиям с экспертными тренерами на каждую фазу цикла.
                            </p>
                        </div>
                    </li>
                    <li class="left-img">
                        <h2 class="mobile-heading">
                            Лунный календарь
                        </h2>
                        <img src="../assets/images/moon_banner.jpg" >
                        <div>
                            <h2 class="desktop-heading">
                                Лунный календарь
                            </h2>
                            <p>
                                Передаем вам подсказки Вселенной! Ēastragene внимательно рассматривает тесную связь планет и человека и под руководством профессионального астролога создает для вас карту на день.
                            </p>
                            <p>
                                Расширенный гороскоп охватывает ну все: ретропланеты, транзитные переходы и прогноз по дню недели.
                            </p>
                        </div>
                    </li>
                </ul>
            </section>
            <section class="banner-with-link">
                <div class="banner-text">
                    <h2>
                        Путь к балансу<br/> через заботу<br/> о себе
                    </h2>
                    <p>
                        Следовать здоровому образу жизни с учетом фазы циклов — легко при поддержке экспертов команды Ēastragene.
                    </p>
                    
                </div>
                 <a class="download-to-phone" target="_blank" href="https://apps.apple.com/ru/app/%C4%93astragene/id6657984011"><span><span class="apple-icon">&nbsp;</span><span>Скачать на iPhone</span></span><img class="q-icon" src="../assets/images/qr.jpg" ></a> 
            </section>
            <section class="experts-section">
                <h2>Эксперты</h2>
                <ul class="experts-list">
                    <!-- <li class="wide">
                        <span class="picture-wrapper">
                            <picture>
                                <img src="../assets/images/Yana2.jpg" >
                            </picture>
                        </span>
                        <div>
                            <h3>Яна Цокунова, <br/>Основатьель проекта Ēastragene</h3>
                            <ul class="role">
                                <li>Nutritionist (Stanford University)</li>
                                <li>Natural Health for Women (Endeavour University)</li>
                                <li>Anatomy (University of Michigan)</li>
                                <li>Тренер по CrossFit (L1)</li>
                            </ul>
                            <p>
                                Я создала приложение Ēastragene, чтобы помочь женщинам быть в гармонии с собой. Здесь они могут найти всё, что нужно для заботы о своем теле и душе: от тренировок и питания до гармонии с лунным циклом. Потому что каждая из нас заслуживает быть в гармонии с собой.
                            </p>
                        </div>
                    </li> -->
                    <li>
                        <span class="picture-wrapper">
                            <picture>
                                <source srcset="../assets/images/mar_mob.jpg" media="(max-width: 1024px)" />
                                <img src="../assets/images/mar.jpg" >
                            </picture>
                        </span>
                        <div>
                            <h3>Мария Филиппова</h3>
                            <ul class="role">
                                <li>Тренер по CrossFit, Гимнастике и Тяжелой Атлетике</li>
                                <li>КМС по Тяжелой и Легкой атлетике</li>
                                <li>Чемпионка России Siberian Showdown</li>
                                <li>Ex амбассадор Reebok в России</li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <span class="picture-wrapper">
                            <picture>
                                <source srcset="../assets/images/nat_mob.jpg" media="(max-width: 1024px)" />
                                <img src="../assets/images/nat.jpg" >
                            </picture>
                        </span>
                        <div>
                            <h3>Ната Мусатова</h3>
                            <ul class="role">
                                <li>Инструктор по функциональному тренингу с компетенцией более 6 лет в лучших проектах Москвы</li>
                                <li>С 2016 года действующий участник и чемпион массовых соревнований по бегу на средние дистанции</li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <span class="picture-wrapper">
                            <picture>
                                <source srcset="../assets/images/alx_mob.jpg" media="(max-width: 1024px)" />
                                <img src="../assets/images/alx.jpg" >
                            </picture>
                        </span>
                        <div>
                            <h3>Александр Марчак</h3>
                            <ul class="role">
                                <li>Тренер по смешанным боевым единоборствам (ММА), боксу, кикбоксингу, тайскому боксу, грэпплингу, джиу-джитсу, ОФП</li>
                                <li>Многократный призёр всероссийских турниров по ММА</li>
                                <li>Сотрудничество с именитыми Российскими проектами (Яндекс, Московский спорт, SMStratching, My Mafia)</li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <span class="picture-wrapper">
                            <picture>
                                <source srcset="../assets/images/adi_mob.jpg" media="(max-width: 1024px)" />
                                <img src="../assets/images/adi.jpg" >
                            </picture>
                        </span>
                        <div>
                            <h3>Адила Рагимова</h3>
                            <ul class="role">
                                <li>Именитый тренер по аэробике</li>
                                <li>Лицо Российских спортивных брендов одежды</li>
                                <li>Актриса рекламы, театра и кино</li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </section>
        </div>
    </div>
    <section class="rates-section bottom-block">
        <div class="rates-wrapper">
            <h2>
                Знакомьтесь со всеми возможностями Ēastragene
            </h2>
            <div class="rates-list">
                <div class="light-bg list-item">
                    <h3 class="rate-name">Бесплатно</h3>
                    <p class="rate-term">Бессрочно</p>
                    <ul class="included-list">
                        <li class="include">
                            <p>Трекер цикла</p>
                        </li>
                        <li class="not-include">
                            <p>Подробная информация о текущем дне цикла и статистика</p>
                        </li>
                        <li class="not-include">
                            <p>Авторская программа питания с обновлением каждый месяц</p>
                        </li>
                        <li class="not-include">
                            <p>Авторская программа тренировок на все фазы цикла с обновлением каждый месяц</p>
                        </li>
                        <li class="not-include">
                            <p>Лунный календарь</p>
                        </li>
                        <li class="not-include">
                            <p>Библиотека материалов от экспертов по здоровому образу жизни</p>
                        </li>
                    </ul>
                </div>
                <div class="tabs-wrapper">
                    <ul class="tabs">
                        <ng-container *ngIf="currentPlan !== undefined">
                        <li [ngClass]="{'current': plan.id == currentPlan.id}" *ngFor="let plan of plans" (click)="setPlan(plan.id)"><p>{{ plan.title }} <span>{{ plan.badge }}</span></p></li>
                        </ng-container>
                    </ul>
                </div>
                <div class="dark-bg list-item">
                    <ng-container *ngIf="currentPlan !== undefined">
                        <h3 class="rate-name">{{ currentPlan.price_title }}</h3>
                        <p class="rate-term"> {{ currentPlan.price_subtitle }} </p>
                    </ng-container>
                    <ul class="included-list">
                        <li class="include">
                            <p>Трекер цикла</p>
                        </li>
                        <li class="include">
                            <p>Подробная информация о текущем дне цикла и статистика</p>
                        </li>
                        <li class="include">
                            <p>Авторская программа питания с обновлением каждый месяц</p>
                        </li>
                        <li class="include">
                            <p>Авторская программа тренировок на все фазы цикла с обновлением каждый месяц</p>
                        </li>
                        <li class="include">
                            <p>Лунный календарь</p>
                        </li>
                        <li class="include">
                            <p>Библиотека материалов от экспертов по здоровому образу жизни</p>
                        </li>
                    </ul>
                    <div *ngIf="currentPlan?.trial_period"class="label-trial">
                        <ng-container >
                            <p>{{ currentPlan?.trial_period?.trial_period_title }}</p>
                        </ng-container>
                    </div>
                </div>
            </div>
             <div class="bottom-link">
                <h2>Стань ближе к себе</h2>
                <p>Скачай Ēastragene, следи за циклом и получай регулярную поддержку нутрициолога, астролога и тренера</p>
                <a class="download-to-phone" target="_blank" href="https://apps.apple.com/ru/app/%C4%93astragene/id6657984011"><span><span class="apple-icon">&nbsp;</span><span>Скачать на iPhone</span></span><img class="q-icon" src="../assets/images/qr.jpg" ></a>
            </div>
        </div>
        <app-footer />
    </section>
</main>
