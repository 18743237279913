import { Routes } from '@angular/router';
import { HomepageComponent } from './_pages/homepage/homepage.component';
import { LoginComponent } from './_pages/login/login.component';
import { OtpComponent } from './_pages/login/otp/otp.component';
import { PlansComponent } from './_pages/login/plans/plans.component';
import { RegisterComponent } from './_pages/register/register.component';
import { AccountComponent } from './_pages/account/account.component';
import { EditComponent } from './_pages/account/edit/edit.component';
import { SubscribeComponent } from './_pages/account/subscribe/subscribe.component';
import { PaymentResponseComponent } from './_pages/account/payment-response/payment-response.component';
import { PrivacyPolicyComponent } from './_pages/legal/privacy-policy/privacy-policy.component';
import { CandiePolicyComponent } from './_pages/legal/candie-policy/candie-policy.component';
import { PublicOfferComponent } from './_pages/legal/public-offer/public-offer.component';
import { NotFoundComponent } from './_pages/not-found/not-found.component';
import { BlogComponent } from './_pages/blog/blog.component';
import { PostComponent } from './_pages/blog/post/post.component';
import { authGuard, unauthGuard } from './_helpers/auth.guard';

export const routes: Routes = [
  { path: '', component: HomepageComponent, title: 'Ēastragene' },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'cookie-policy', component: CandiePolicyComponent },
  { path: 'public-offer', component: PublicOfferComponent },
  { path: 'login', component: LoginComponent, canActivate: [unauthGuard] },
  { path: 'login/otp', component: OtpComponent, canActivate: [unauthGuard] },
  { path: 'login/plans', component: PlansComponent, canActivate: [unauthGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [unauthGuard]  },
  //{ path: 'account', component: AccountComponent, canActivate: [authGuard] },
  { path: 'account/edit', component: EditComponent, canActivate: [authGuard] },
  { path: 'account/subscribe', component: SubscribeComponent, canActivate: [authGuard] },
  { path: 'account/payment-response', component: PaymentResponseComponent, canActivate: [authGuard] },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/post/:id', component: PostComponent },
  { path: '**', component: NotFoundComponent },
];
