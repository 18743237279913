<main class="main legal-page">
    <div class="main-wrapper">
        <header>
            <a [routerLink]="['/']" class="logo">
                <img src="../assets/images/account_logo.svg" >
            </a>
            <div class="links">
                <a [routerLink]="['/blog']"class="header-link blog-link">
                    <span><img src="../assets/images/blog_dark.svg"></span>
                    <span>Блог</span>
                </a>
                <a [routerLink]="['/login']"class="header-link">
                    <span><img src="../assets/images/account_dark.svg" ></span>
                </a>
            </div>
        </header>
        <div class="content">
            <div class="legal-heading">
                <h2>Политика конфиденциальности</h2>
                <strong>
                    в отношении обработки файлов "сookie"
                </strong>
                <p>(далее по тексту – «Политика»)</p>
                <em>Версия от «18» июля 2024 г., Российская Федерация, г. Москва</em>
            </div>
            <h3>1. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
            <ol>
                <li>
                    <strong>1.1</strong> Настоящая Политика составлена в соответствии с требованиями Федерального закона Российской Федерации «О персональных данных» № 152-ФЗ от 27.07.2006 и иными нормативно-правовыми и подзаконными актами Российской Федерации, принятыми на его исполнение, определяет порядок обработки, управления, блокировки, удаления файлов "cookie", а также виды и цели обработки файлов "cookie", которые собираются Оператором с помощью веб-сайта.
                </li>
                <li>
                    <strong>1.2</strong> Файлы "cookie" — это небольшой фрагмент данных (в т. ч. текстовые файлы), отправляемые сервером веб-сайта и хранимые на компьютере Пользователя, которые веб-клиент или веб-браузер каждый раз пересылает на сервер веб-сайта в НТТР-запросе при попытке открыть соответствующую страницу веб-сайта. Файлы "cookie" обеспечивают сбор информации о действиях Пользователя на веб-сайте и отслеживают статистику посещения веб-сайта.
                </li>
                <li>
                    <strong>1.3</strong> На данном веб-сайте используются файлы "сookie" с целью реализации и улучшения функциональных возможностей веб-сайта, обеспечения финансовой безопасности, продвижения веб-сайта, привлечения новых клиентов, обеспечения качественного сервиса. Информация, полученная из файлов "сookie", используется Оператором в той мере, в которой это необходимо для достижения целей их использования.
                </li>
                <li>
                    <strong>1.4</strong> Пользователь может столкнуться со встроенным контентом, либо Пользователя могут перенаправить на другие веб-сайты для определенных активностей. Указанные веб-сайты и встроенный контент могут использовать свои собственные файлы "сookie". Оператор не контролирует размещение файлов "сookie" другими веб-сайтами, даже если Пользователь перенаправлен на них с данного веб-сайта.
                </li>
                <li>
                    <strong>1.5</strong> Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов "сookie" и использование технологии «JavaScript»).
                </li>
                <li>
                    <strong>1.6</strong> В целях информирования Пользователя и получения его согласия на обработку Оператор размещает на веб-сайте уведомление следующего характера: «Мы используем "cookie" для быстрой и удобной работы сайта. Продолжая пользоваться сайтом, вы подтверждаете свое согласие на сбор и обработку файлов "cookiе"
                </li>
            </ol>
            <h3>1. ВИД И ТИП ФАЙЛОВ "COOKIE"</h3>
            <ol>
                <li>
                    <strong>2.1</strong> Веб-сайт производит сбор и обработку обезличенных данных о посетителях (файлов "сookie") с помощью сервисов интернет-статистики («Яндекс Метрика»), которые необходимы для работы сервисов "Яндекс", а также данные, необходимые для организации продвижения веб-сайта в поисковых системах.
                </li>
                <li>
                    <strong>2.2</strong> "Яндекс Метрика" — это услуга веб-анализа, предоставляемая ООО "Яндекс" (ОГРН: 1027700229193; ИНН: 7736207543; адрес: 119021, г. Москва, ул. Льва Толстого, д. 16), которое использует собранные данные для отслеживания и изучения использования веб-сайта, подготовки отчетов о его деятельности и обмена ими с другими службами «Яндекс». Собираемая информация: файлы "сookie" и данные об использовании. Для уточнения деталей, перейдите в «Политику конфиденциальности» на странице <a href="https://yandex.ru/legal/confidential/" target="_blank">https://yandex.ru/legal/confidential/</a>. Пользователь может отказаться от передачи данных в «Яндекс Метрику», установив «Блокировщик» от «Яндекс Метрики».
                </li>
            </ol>
            <table class="bordered-table">
                <tr>
                    <td>№</td>
                    <td>Наименование:</td>
                    <td>Описание:</td>
                </tr>
                <tr>
                    <td>1.</td>
                    <td>Основные (обязательные, строго необходимые):</td>
                    <td>Необходимы для функционирования и корректной работы веб-сайта, его основных (базовых) функций, а также для идентификации ботов и защиты веб-сайта от вредоносных спам-атак. Как правило, эти файлы активируются только в ответ на действия Пользователя, аналогичные запросу на получение доступа к функциям веб-сайта или при заполнении форм. Пользователь может настроить браузер таким образом, чтобы он уведомлял его об их использовании либо блокировал файлы "cookie", но в таком случае возможно, что некоторые разделы веб-сайта не будут функционировать надлежащим образом.</td>
                </tr>
                <tr>
                    <td>2.</td>
                    <td>Аналитические и эксплуатационные:</td>
                    <td>Необходимы для идентификации Пользователя, подсчитывают количество посещений и источников трафика, чтобы оценивать и улучшать работу веб-сайта. Благодаря им Оператор знает какие страницы являются наиболее и наименее популярными, и видит, каким образом Пользователь перемещается по веб-сайту. Все данные, собираемые при помощи этих "cookie", группируются в статистику, являются агрегированными и анонимными, так как не идентифицирует какого-либо отдельного посетителя. Если Пользователь не одобрит (запретит) их использование у Оператора не будет данных о посещении Пользователем веб-сайта.</td>
                </tr>
                <tr>
                    <td>3.</td>
                    <td>Рекламные:</td>
                    <td>Необходимы для запоминания веб-страниц, которые Пользователь посетил и ссылок, по которым Пользователь проходил, а также используются для персонализации показываемой рекламы в зависимости от выбранных предпочтений во время навигации.</td>
                </tr>
                <tr>
                    <td>4.</td>
                    <td>Данные, которые автоматически передаются в процессе использования веб-сайта:</td>
                    <td>Уникальные данные об оборудовании (IP адрес, информация о виде операционной системы устройства и браузере, технические характеристики оборудования и программного обеспечения, географическое местоположение, дата, время использования веб-сайта и реферер (адрес предыдущей страницы), посещенные веб-сайты, объявления, на которые нажимает Пользователь. Указанные данные необходимы для обеспечения финансовой безопасности, оформления заказа на покупку подписки, обеспечения корректной работы основных функциональных возможностей веб-сайта, улучшения качества обслуживания и оптимизации веб-сайта, отслеживания статистики посещений, рекламы, продвижения веб-сайта в поисковых системах.</td>
                </tr>
            </table>
            <h3>3. УПРАВЛЕНИЕ ФАЙЛАМИ "СOOKIE", ИХ БЛОКИРОВКА И УДАЛЕНИЕ</h3>
            <ol>
                <li>
                    <strong>3.1</strong> Пользователь вправе ограничить использование файлов "cookie" или полностью запретить их установку. В большинстве браузеров предусмотрены способы управления поведением файлов "cookie", например длительностью их хранения — либо с помощью встроенных функций, либо с помощью сторонних плагинов.
                </li>
                <li>
                    <strong>3.2</strong> Пользователь может очистить все файлы "сookie", установленные веб-сайтом, который Пользователь посетил.
                </li>
                <li>
                    <strong>3.3</strong> Ограничение или отключение использования файлов "cookie" может ограничить функциональность веб-сайта или помешать его правильной работе.
                </li>
                <li>
                    <strong>3.4</strong> Узнать больше о том, как управлять файлами "соокіе" в популярных веб-браузерах Пользователь может по следующим ссылкам:
                </li>
                <li>
                    <ul>
                        <li>
                            - <a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank">Microsoft Edge, Internet Explorer</a>
                        </li>
                        <li>
                            - <a href="https://support.google.com/chrome/answer/95647?hl=en-GB" target="_blank">Google Chrome</a>
                        </li>
                        <li>
                            - <a href="https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox" target="_blank">Firefox</a>
                        </li>
                        <li>
                            - <a href="https://support.apple.com/ru-ru/guide/safari/sfri11471/mac" target="_blank">Safari для Mac, IOS, iPad OS (iPhone / iPad)</a>
                        </li>
                        <li>
                            - <a href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/" target="_blank">Opera</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>3.5</strong> Чтобы получить больше информации о том, как управлять файлами "cookie" и удалять их, посетите сайт <a href="http://aboutcookies.org" target="_blank">http://aboutcookies.org</a>. Для получения более подробной информации о файлах "cookie" и о том, как ими управлять, посетите <a href="http://youronlinechoices.eu" target="_blank">http://youronlinechoices.eu</a> (если вы находитесь в ЕС) или <a href="http://aboutads.info" target="_blank">http://aboutads.info</a> (если вы находитесь в США).
                </li>
                <li>
                    <strong>3.6</strong> Если Пользователь приобретает новое устройство (гаджет), устанавливает или обновляет интернет-браузер, стирает или иным образом изменяете его файлы "сookie", это также может привести к очистке файлов "сookie".
                </li>
            </ol>
            <h3>4. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
            <ol>
                <li>
                    <strong>4.1</strong> Если Оператор изменит использование файлов "сookie", соответствующие изменения будут внесены в данную Политику в отношении обработки файлов "сookie". Если у Пользователя есть вопросы или предложения касательно данной Политики, он может сообщить их Оператору путем отправки электронного письма по адресу электронной почты.
                </li>
            </ol>
            <h3>5. РЕКВИЗИТЫ ОПЕРАТОРА</h3>
            <div class="table-container">
                <table class="bordered-table">
                    <tr>
                        <td>Наименование:</td>
                        <td>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ВЕДА»</td>
                    </tr>
                    <tr>
                        <td>ОГРН:</td>
                        <td>1237700856887</td>
                    </tr>
                    <tr>
                        <td>ИНН:</td>
                        <td>7743433550</td>
                    </tr>
                    <tr>
                        <td>Юридический адрес:</td>
                        <td>125212, г. Москва, ул. Адмирала Макарова, д. 8, стр. 1, помещ. 6/1</td>
                    </tr>
                    <tr>
                        <td>E-mail:</td>
                        <td><a href="mailto:Ya.tsokunova@eastragene.com">Ya.tsokunova&#64;eastragene.com</a></td>
                    </tr>
                </table>
            </div>
            <app-footer />
        </div>
    </div>
</main>
